.label {
  background-color: $light-gray;
  color: $label-badget-color;
  font-family: "Open Sans";
  font-size: 10px;
  font-weight: 600;
  padding: 3px 8px;
  text-shadow: none;
  }

.badge {
  background-color: $light-gray;
  color: $label-badget-color;
  font-family: "Open Sans";
  font-size: 11px;
  font-weight: 600;
  padding-bottom: 4px;
  padding-left: 6px;
  padding-right: 6px;
  text-shadow: none;
  }

.label-primary, .badge-primary {
  background-color: $navy;
  color: white;
  }

.label-success, .badge-success {
  background-color: $blue;
  color: white;
  }

.label-warning, .badge-warning {
  background-color: $yellow;
  color: white;
  }

.label-warning-light, .badge-warning-light {
  background-color: $yellow;
  color: white;
  }

.label-danger, .badge-danger {
  background-color: $red;
  color: white;
  }

.label-info, .badge-info {
  background-color: $lazur;
  color: white;
  }

.label-inverse, .badge-inverse {
  background-color: #262626;
  color: white;
  }

.label-white, .badge-white {
  background-color: white !important;
  color: #5e5e5e !important;
  }

.label-white, .badge-disable {
  background-color: #2a2e36 !important;
  color: #8b91a0 !important;
  }
