/*
 *
 *   CRIMS LIFT - Responsive
 *   version 1.0
 *
*/

// Google Fonts
@import url("//fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&lang=en");

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Font-awesome
@import "node_modules/font-awesome/scss/font-awesome";


// Variables, Mixins
@import "variables";
@import "mixins";

// Elements
@import "typography";
@import "navigation";
@import "top_navigation";
@import "buttons";
@import "badgets_labels";
@import "elements";
@import "sidebar";
@import "base";
@import "pages";
@import "_image_viewer";

// Landing page
@import "landing";


@import "metismenu";

// Media query style
@import "media";

// Custom style
// Your custom style to override base style
@import "custom";


// ligands
@import "ligands";
