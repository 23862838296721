// Font
$font-stack: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;

// Basic Colors
$navy: #1ab394;           // Primary color
$dark-gray: #c2c2c2;      // Default color
$blue: #1c84c6;           // Success color
$lazur: #23c6c8;          // Info color
$yellow: #f8ac59;         // Warrning color
$red: #ed5565;            // Danger color

// Various colors
$text-color: #676a6c;           // Body text
$gray: #f3f3f4;                 // Background wrapper color
$light-gray: #d1dade;           // Default label, badget
$label-badget-color: #5e5e5e;
$light-blue: #f3f6fb;

// Spiner color and margin
$spin-color: $navy;
$spin-margin: 0 auto;

// IBOX colors ( default panel colors)
$border-color: #e7eaec;   // IBox border
$ibox-title-bg: white;    // IBox Background header
$ibox-content-bg: white;  // IBox Background content

//Sidebar width
$sidebar-width: 220px;

// Boxed layout width
$boxed-width: 1200px;
$boxed-backgound: image-url("patterns/shattered.png");

//Border radius for buttons
$btn-border-radius: 3px;

//Navigation
$nav-bg: #2f4050;
$nav-profil-pattern: image-url("patterns/header-profile.png") no-repeat;
$nav-text-color: #a7b1c2;
