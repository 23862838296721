.crimsgreen { background-color:#007C80; margin-bottom: 0;}
.crimsgreen .navbar-left {font-size:3em;margin-left:50px;}
.crimsgreen .navbar-top-links, .crimsgreen .navbar-top-links li a {color:#ffffff;}
.crimsgreen ul {padding:0;margin:0;}
.crimsgreen .navbar-top-links, .crimsgreen .navbar-top-links li ul.dropdown-menu {background-color:#007C80;}
.modal-vlarge { width:900px;margin: 30px auto;}
.navbar-default {background-color: transparent;border-color: #2f4050;}
.wizard > .steps > ul > li { width: 33% !important;}
.wizard > .steps > ul > li > a.active { background-color: #1ab394;}

.row-warning { border: solid 2px; border-color: #F5DC0F; }
.row-danger { border: solid 2px;border-color: #F9614B; }


.valid-date table {width:100%; font-size: 15px;}
.valid-date table tr td button.active{background-color:#18a689; color:#ffffff }
.valid-date table button.btn-default {background-color:#FFFFFF;border-color:#C7D0C7;color:#303230;}
.valid-date button.btn-default:disabled {background-color: #cccccc !important;border-color: #F9F9F9 !important; color: rgb(254, 254, 254) !important;}
