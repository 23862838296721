.shelf-highlight {background-color: #18a689 !important;}
td .badge {color:white;font-size:medium;}
.badge.badge-clear { background-color:#7CC8CF}
.badge.badge-denatured { background-color:#034380}
.badge.badge-precipitate {background-color:#4199eb}	    					
.badge.badge-interesting {background-color:#c7c70e}
.badge.badge-micro-crystals {background-color:#f07171}
.badge.badge-cluster {background-color:#8f4d4d}
.badge.badge-single-crystal {background-color:#AF0A07}
.badge.badge-summary {background-color:#D64ECF}
.badge.badge-harvested {background-color:#000000;}

.no-score { background-color:#fff}
.score-clear { background-color:#7CC8CF}
.score-denatured { background-color:#034380}
.score-precipitate {background-color:#4199eb}	    					
.score-interesting {background-color:#c7c70e}
.score-micro-crystals {background-color:#f07171}
.score-cluster {background-color:#8f4d4d}
.score-single-crystal {background-color:#AF0A07}
.score-summary {background-color:#D64ECF}
.score-harvested {background-color:#000000;color:#FFFFFF;}

h3 strong.score-clear {background-color:#FFFFFF; color:#7CC8CF;}
h3 strong.score-denatured { background-color:#FFFFFF;color:#034380;}
h3 strong.score-precipitate {background-color:#FFFFFF;color:#4199eb;}	    					
h3 strong.score-interesting {background-color:#FFFFFF;color:#c7c70e;}
h3 strong.score-micro-crystals {background-color:#FFFFFF;color:#f07171;}
h3 strong.score-cluster {background-color:#FFFFFF;color:#8f4d4d;}
h3 strong.score-single-crystal {background-color:#FFFFFF;color:#AF0A07;}
h3 strong.score-summary {background-color:#FFFFFF;color:#D64ECF;}
h3 strong.score-harvested {background-color:#000000;color:#FFFFFF;}

.table.plate > thead > tr > th, 
.table.plate > tbody > tr > th, 
.table.plate > tfoot > tr > th, 
.table.plate > thead > tr > td, 
.table.plate > tbody > tr > td, 
.table.plate > tfoot > tr > td {
border-top: 1px solid #e7eaec;
line-height: 1.42857;
padding: 5px;
vertical-align: top;
}

.view-image {margin-left: 0; margin-top:1%}
.image-buttons{margin-bottom:1%;}


.btn-large-dim {
	width:155px;
}
.col-md-6.btn-cutshape {
	padding:0;
}

.btn-large-dim {
	height:100%;
}

.panel-body.cutShapes {
	padding:15px 0 0 0;
}

.btn-lightGrey {
	background-color: #f5f5f5;;
	border-color: #1ab394;
}
.btn-lightGrey:hover {
	background-color: #FFFDFD;
	border-color: #1ab394;
}

button.btn-spaced { margin-right:2%;}

button.btn-lightGrey.dim {
	box-shadow: inset 0px 0px 0px #b3b3b3, 0px 5px 0px 0px #1ab394, 0px 10px 5px #999999;
}

.button-shape-text {
	font-size:12px;
	color:#007C80;
}
