.nav, .pagination, .carousel, .panel-title a { cursor: pointer; }
.green {
    color: #5cb85c;
}
.bold {
    font-weight: bold;
}
.maxwidth {
    max-width: 100%;
    width:410;
    z-index: 10000;
}
.staranisomaxwidth {
    max-width: 800;
    width:410;
    z-index: 10000;
}

.hov:hover {
    background-color: #CEECF5;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
}
.reallysmall {
    font-size: x-small;
    text-align: center;   
    vertical-align: middle;
}
.select-helper {
    position: absolute;
    border: 1px dashed red;
    background: red;
    opacity: 0.2;
}

.navbar-collapse.in {
    overflow-y: hidden;
}
.capitalize {
    font-variant: small-caps;
}
.typeahead-demo .custom-popup-wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    background-color: #f9f9f9;
}

.typeahead-demo .custom-popup-wrapper > .message {
    padding: 10px 20px;
    border-bottom: 1px solid #ddd;
    color: #868686;
}

.gitem  {
    margin:0px; // ??
    cursor: pointer;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
}

.nav, .pagination, .carousel, .panel-title a { cursor: pointer; }

.sliding-steps main,
.sliding-steps main .well {
    margin: 0;
}
.sliding-steps main {
    position: relative;
    margin-bottom: 1rem;
    overflow: hidden;
}

.sliding-steps main .well {
    position: absolute;
    width: 100%;
    height: 100%;
}

.sliding-steps .form-step.ng-enter, .sliding-steps .form-step.ng-leave {
    -webkit-transition: transform .5s ease-in-out;
    transition: transform .5s ease-in-out;
}
.sliding-steps .step-forward .form-step.ng-enter {
    transform: translateX(100%);
}
.sliding-steps .step-forward .form-step.ng-enter.ng-enter-active {
    transform: translateX(0);
}
.sliding-steps .step-forward .form-step.ng-leave {
    /*transform: translateX(100%);*/
}
.sliding-steps .step-forward .form-step.ng-leave.ng-leave-active {
    transform: translateX(-100%);
}

.sliding-steps .step-backward .form-step.ng-enter {
    transform: translateX(-100%);
}
.sliding-steps .step-backward .form-step.ng-enter.ng-enter-active {
    transform: translateX(0);
}
.sliding-steps .step-backward .form-step.ng-leave {
    /*transform: translateX(100%);*/
}
.sliding-steps .step-backward .form-step.ng-leave.ng-leave-active {
    transform: translateX(100%);
}

[scroll-glue-bottom],
[scroll-glue]{
    height: 650px;
    overflow-y: scroll;
}
.vert-scroll {
    overflow-x: scroll;
}
.table-scroll thead {
    display: table;
    //display:  block;
    width: 100%;
    table-layout: initial;
}

.table-scroll tbody {
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
    display: block;
    table-layout: auto;
    //table-layout: fixed;
}

.table-scroll tr {
    // display: table;
    table-layout: inherit;
    width: 100%;
}

.table-scroll td {
    height: 47px; // needed in order to keep rows from collapsing
}
.ng-table th.sortable .sort-indicator {
    display: inline-block;
}


.table-scroll thead {
    display: table;
    table-layout: inherit;
}

.table-scroll tbody {
    width:210%;
    overflow-y: auto;
    display: block;
    table-layout: fixed;
}

.table-scroll tr {
    display: table;
    table-layout: inherit;
    width: 100%;
}

.table-scroll td {
    height: 47px; // needed in order to keep rows from collapsing
}
.score0 {
    color: darken(#12e7fa, 10%);
}
.score1 {
    color: darken(#0e1ef5, 10%);
}
.score2 {
    color: darken(#4199eb, 10%);
}
.score3 {
    color: darken(#c7c70e, 10%);
}
.score4 {
    color: darken(#f07171, 10%);
}
.score5 {
    color: darken(#8f4d4d, 10%);
}
.score6 {
    color: darken(#fa0505, 10%);
}
.score7 {
    color: #f00ce5;
}
.bgscore0 {
    background-color: lighten(#12e7fa, 10%);
}
.bgscore1 {
    background-color: lighten(#0e1ef5, 10%);
}
.bgscore2 {
    background-color: lighten(#4199eb, 10%);
}
.bgscore3 {
    background-color: lighten(#c7c70e, 10%);
}
.bgscore4 {
    background-color: lighten(#f07171, 10%);
}
.bgscore5 {
    background-color: lighten(#8f4d4d, 10%);
}
.bgscore6 {
    background-color: lighten(#fa0505, 10%);
}
.bgscore7 {
    background-color: #f00ce5;
}
.verybig .modal-dialog {
    width:90%;
    //margin-left:-375px;
}
.transparent {
    opacity:0;
}
.custom_tabs .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background: none;
  border-color: #DDDDDD #DDDDDD transparent;
  border-bottom: white;
  border-image: none;
  border-style: solid;
  border-width: 1px;
  color: #555555;
  cursor: default; }
.custom_tabs .nav > li.active {
        border-left: 0px solid #19aa8d; background: none; }
            .custom_tabs .nav-pills > li > a {
                    border-radius: 4px; }
.custom_tabs .tab-content {
      border: 1px solid #ddd;
        border-radius: 1px;
    padding: 15px;
}
.custom_tabs_inside .tab-content {
      border: 1px solid #ddd;
        border-radius: 1px;
        border-top:none;
    padding: 15px;
}
.custom-slider.rzslider .rz-bar {
    background: #ffe4d1;
    height: 2px;
}
.custom-slider.rzslider .rz-selection {
    background: #1ab394;
}

.custom-slider.rzslider .rz-pointer {
    width: 8px;
    height: 16px;
    top: auto; /* to remove the default positioning */
    bottom: 0;
    background-color: #337ab7;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.custom-slider.rzslider .rz-pointer:after {
    display: none;
}

.custom-slider.rzslider .rz-bubble {
    bottom: 14px;
}

.custom-slider.rzslider .rz-limit {
    font-weight: bold;
    color: #1ab394;
}

.custom-slider.rzslider .rz-tick {
    width: 1px;
    height: 10px;
    margin-left: 4px;
    border-radius: 0;
    background: #ffe4d1;
    top: -1px;
}

.custom-slider.rzslider .rz-tick.rz-selected {
    background: #1ab394;
}
